@tailwind base;

@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
    @apply font-montserrat;
  }
  }

body {
  margin: 0;
  
}



